<template>
  <div class="bg-white" id="LoginWrap">
    <div class="login_btn_box m-auto text-center">
      <CLink to="/home">
        <CImg src="/img/threespace/logo/logo_3space_new@2x.png" width="200px" />
      </CLink>

      <CRow class="justify-content-center mt-5">
        <CCol class="col-12 text-center text-dark">
          <p>{{ $t("login.newPwMessage") }}</p>
          <p class="text-gray">{{ $t("validation.password") }}</p>
        </CCol>
      </CRow>

      <CForm @keypress.enter.prevent="onSubmitResetPassword()">
        <CRow class="justify-content-center mt-3 mb-2">
          <CCol class="col-12 text-left">
            <CInput
              type="password"
              :lazy="false"
              :value.sync="$v.form.password.$model"
              :isValid="checkIfValid('password')"
              :placeholder="$t('login.newPw')"
              autocomplete="new-password"
              :invalidFeedback="$t('validation.requiredPassword')"
              class="mb-2"
            />
            <CInput
              type="password"
              :value.sync="$v.form.confirmPassword.$model"
              :isValid="checkIfValid('confirmPassword')"
              :placeholder="$t('login.newConfirmPw')"
              autocomplete="new-password"
              :invalidFeedback="$t('validation.requiredPassword')"
              class="mb-2"
            />
          </CCol>
        </CRow>

        <p class="text-danger mt-3">{{ errorMessage }}</p>
        <b-button block class="space_btn" @click="onSubmitResetPassword()">
          {{ $t("login.changePassword") }}
        </b-button>
      </CForm>
    </div>

    <!-- 비밀번호 재설정 완료 -->
    <b-modal
      id="resetSuccessModal"
      ref="resetSuccessModal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center py-5"
    >
      <p class="font-lg font-weight-bold">
        {{ $t("login.completeResetPassword") }}
      </p>
      <p>{{ $t("login.gotoLogin") }}</p>
      <b-button squared variant="black" class="mt-3 px-5" @click="onClickLogin">
        {{ $t("button.confirm") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers, minLength, required, sameAs } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const authHelper = createNamespacedHelpers("auth");

export default {
  name: "ResetPassword",
  data() {
    return {
      form: {
        member: null,
        password: null,
        confirmPassword: null
      },
      errorMessage: null
    };
  },
  computed: {
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        strongPass: helpers.regex(
          "strongPass",
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
        )
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.form.member = this.$route.query.idxMember;
    });
  },
  methods: {
    ...authHelper.mapActions(["updatePassword"]),
    onSubmitResetPassword() {
      this.$v.$touch();
      if (this.isValid) {
        this.updatePassword(this.form)
          .then(() => {
            this.errorMessage = null;
            this.$refs["resetSuccessModal"].show();
          })
          .catch(error => {
            this.$log.error(error);
            this.errorMessage = this.$t("login.error.resetPw");
          });
      }
    },
    onClickLogin() {
      this.$router.push({ name: "Login" });
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    }
  }
};
</script>
